import { globalStyles } from '@/styles/stitches/global';
import type { AppProps } from 'next/app';

import Router from 'next/router';

import NProgress from 'nprogress';

import { QueryClient, QueryClientProvider } from 'react-query';
import { ToastContainer } from 'react-toastify';
import Modal from 'react-modal';
import { Amplify } from 'aws-amplify';
// import { ErrorBoundary } from '@highlight-run/next/client';

import 'react-toastify/dist/ReactToastify.css';

Router.events.on('routeChangeStart', () => {
  NProgress.start();
});

Router.events.on('routeChangeComplete', () => {
  NProgress.done();
});

Router.events.on('routeChangeError', () => {
  NProgress.done();
});

export const queryClient = new QueryClient();

Modal.setAppElement('#__next');

globalStyles();

Amplify.configure({
  ssr: true,
  region: 'us-east-1',
  Auth: {
    userPoolId: process.env.AWS_USER_POOL_ID,
    userPoolWebClientId: process.env.AWS_POOL_WEB_CLIENT_ID,
    identityPoolId: process.env.AWS_IDENTITY_POOL_ID,
  },
});

function MyApp({ Component, pageProps: { ...pageProps } }: AppProps) {
  return (
    <>
      {/* <HighlightInit
        projectId={'jd46k4g5'}
        tracingOrigins
        reportConsoleErrors
        networkRecording={{
          enabled: true,

          recordHeadersAndBody: true,
          urlBlocklist: [],
        }}
        disableSessionRecording
      /> */}
      {/* <ErrorBoundary> */}
      <QueryClientProvider client={queryClient}>
        <ToastContainer />

        <Component {...pageProps} />
      </QueryClientProvider>
      {/* </ErrorBoundary> */}
    </>
  );
}

export default MyApp;
