import { globalCss } from '@/styles/stitches';

export const globalStyles = globalCss({
  '*': {
    boxSizing: 'border-box',
    padding: 0,
    margin: 0,
    fontFamily: 'Poppins',
  },
  body: {
    '-webkit-font-smoothing': 'antialiased',
    fontFamily: '$default',
  },
  button: {
    cursor: 'pointer',
  },

  '.ReactModal__Overlay': {
    backgroundColor: 'rgba(0,0,0, 0.8) !important',
    opacity: 0,
    zIndex: 99999,
    transition: 'all 500ms ease-in-out',
  },

  '.ReactModal__Overlay--after-open': {
    opacity: 1,
    transform: 'translateX(0px)',
  },

  '.ReactModal__Content.ReactModal__Content--after-open': {
    top: '50% !important',
    left: '50% !important',

    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    inset: 0,
  },

  '.ReactModal__Overlay--before-close': {
    opacity: 0,
    transform: 'translateX(-100px)',
  },

  '.Toastify__progress-bar--success': {
    background: '#569E6B !important',
  },
  '.Toastify__progress-bar--error': {
    background: '#DF6767 !important',
  },

  '.Toastify__toast-container ': {
    width: '450px !important ',
    zIndex: '999999999 !important',
  },

  '.Toastify__toast': {
    width: '700px',
    zIndex: '99',
    minHeight: '120px !important',
    padding: '0px !important',
    position: 'relative',
    borderRadius: '10px 0px 0px 10px !important',

    '&.Toastify__toast--success:before': {
      position: 'absolute',
      width: '14px',
      height: '100%',
      background: '#569E6B',
      content: '',
      borderRadius: '10px 0px 0px 10px',
    },

    '&.Toastify__toast--error:before': {
      position: 'absolute',
      width: '14px',
      height: '100%',
      background: '#DF6767',
      content: '',
      borderRadius: '10px 0px 0px 10px',
    },
  },

  '.Toastify__toast-body': {
    div: {
      '&:last-child': {
        flex: 'none !important',

        display: 'flex',
        justifyContent: 'center',
        // marginRight: '55px',
        width: 'auto',
        strong: {
          fontWeight: '$regular',
          fontSize: '$lg',
          color: '#000',
          marginLeft: '55px',
        },
      },
    },

    img: {
      marginLeft: '30px',
    },
  },
});
