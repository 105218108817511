export const colors = {
  primary: '#4521AB',
  secondary: '#4421A5',

  primaryHover: '#AEA0E8',

  primaryButtonBackground: '#522EB7',
  primaryButtonColor: '#F5F5F5',
  primaryButtonBackgroundHover: '#2C0E80',

  secondaryButtonBackground: '#F5F5F5',
  secondaryButtonColor: '#522EB7',

  texts: '#262626',

  errorMessage: '#ff0000',

  brightColour: '#fff',
  weakBrightColour: '#fcfcfc',

  activeSideMenuBackground: '#F5F2FF',
  activeSideMenuColor: '#522EB7',

  unActiveSideMenuColor: '#fcfcfc',

  unActiveSideMenuBackgroundHover: '#694BBC',
  unActiveSideMenuColorHover: '#D6CDF9',

  lines: '#D9D9D9',

  placeholders: '#AFAFAF',
};

export const fontSizes = {
  xxs: '0.625rem', // 10px
  xs: '0.75rem', // 12px
  sm: '0.875rem', // 14px
  md: '1rem', // 16px
  lg: '1.125rem', // 18px
  xl: '1.25rem', // 20px
  '2xl': '1.5rem', // 24px
  '4xl': '2rem', // 32px
  '5xl': '2.25rem', // 36px
  '6xl': '3rem', // 48px
  '7xl': '4rem', // 64px
  '8xl': '4.5rem', // 72 px
  '9xl': '6rem', // 96px
};

export const fontWeights = {
  regular: '400',
  medium: '500',
  semiBold: '600',
  bold: '700',
};

export const fonts = {
  default: 'Poppins, sans-serif',
  code: 'monospace',
};

export const lineHeights = {
  shorter: '125%',
  short: '140%',
  base: '160%',
  tall: '180%',
};

export const radii = {
  px: '1px',
  xs: '4px',
  sm: '6px',
  md: '10px',
  lg: '16px',
  xl: '32px',
  '2xl': '50px',
  full: '99999px',
};

export const space = {
  // 16xrem
  1: '0.25rem', // 4px
  2: '0.5rem', // 8px
  3: '0.75rem', // 12px
  4: '1rem', // 16px
  5: '1.25rem', // 20px
  6: '1.5rem', // 24px
  7: '1.75rem', // 28px
  8: '2rem', // 32px
  9: '2.25rem', // 36px
  10: '2.5rem', // 40px
  11: '2.625rem', // 42px
  12: '2.75rem', // 44px
  13: '2.875rem', // 46px
  14: '3rem', // 48px
  15: '3.75rem', // 60px
  16: '4rem', // 64px
  18: '4.25rem', // 68px
  20: '5rem', // 80px
  22: '5.25rem', // 92px
  // 40: '10rem',
  // 44: '11rem',
  // 46: '11.5rem',
  // 64: '16rem',
  // 80: '20rem',
};
